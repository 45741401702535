import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tabs,
  Tab,
  Pagination,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import FormattedValue from './FormattedValue';
import CommentsSection from './CommentsSections';
import { AuthContext } from '../context/AuthContext';

// Helper function to shorten wallet addresses
function shortenAddress(address) {
  if (!address || address.length < 8) return address; 
  return `${address.slice(0, 4)}...${address.slice(-4)}`;
}

// Styling Tabs to match the new design
const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#233c48',
  borderRadius: '12px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  '& .MuiTabs-indicator': {
    backgroundColor: '#2badee',
    height: '3px',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '1rem',
  color: '#92b7c9',
  padding: '12px 24px',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#2badee',
    borderRadius: '8px',
  },
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1a8dcc',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: '#111c22',
  borderRadius: '12px',
  border: '1px solid #325567',
  overflow: 'hidden',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#92b7c9',
  borderBottom: '1px solid #325567',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    color: '#92b7c9',
  },
  '& .Mui-selected': {
    backgroundColor: '#2badee',
    color: '#111c22',
  },
}));

function TokenDetailsTabs({ tokenId, solPrice = 180 }) { 
  const [value, setValue] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [topTraders, setTopTraders] = useState([]);
  const [holders, setHolders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user } = useContext(AuthContext);
  const [transactionOccurred, setTransactionOccurred] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (value === 0) {
          const response = await axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}/transactions`, {
            params: { page: currentPage, pageSize: 10 },
          });
          setTransactions(response.data.transactions);
          setTotalPages(response.data.totalPages);
        } else if (value === 1) {
          const response = await axios.get(`https://backend.memebrewery.fun/api/pgtokens/tokens/${tokenId}/top-traders`, {
            params: { page: currentPage, pageSize: 10 },
          });
          setTopTraders(response.data.topTraders);
          setTotalPages(response.data.totalPages);
        } else if (value === 2) {
          const response = await axios.get(`https://backend.memebrewery.fun/api/pgtokens/tokens/${tokenId}/holders`, {
            params: { page: currentPage, pageSize: 10 },
          });
          setHolders(response.data.holders);
          setTotalPages(response.data.totalPages);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    setTransactionOccurred(false);
  }, [value, currentPage, tokenId, transactionOccurred]);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const calculateActiveDuration = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const totalMinutesActive = Math.floor((currentDate - createdDate) / (1000 * 60));
    const hoursActive = Math.floor(totalMinutesActive / 60);
    const minutesActive = totalMinutesActive % 60;

    if (hoursActive < 24) {
      return `${hoursActive}h ${minutesActive}m`;
    } else {
      const daysActive = Math.floor(hoursActive / 24);
      const remainingHoursActive = hoursActive % 24;
      return `${daysActive}d ${remainingHoursActive}h`;
    }
  };

  return (
    <Box className="w-full mt-4 px-2 sm:px-4">
      {/* Tabs */}
      <Box className="overflow-x-auto">
        <StyledTabs value={value} onChange={handleChange} variant="fullWidth">
          <StyledTab label="Transaction History" />
          <StyledTab label="Top Wallet Traders" />
          <StyledTab label="All Holders" />
          <StyledTab label="Comments" />
        </StyledTabs>
      </Box>

      {value === 0 && (
        <Box mt={3} className="w-full overflow-x-auto">
          <Typography variant="h6" sx={{ color: '#fff', mb: 2 }}>
            Transaction History
          </Typography>
          <StyledTableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <Table className="min-w-[600px]">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Price (SOL)</StyledTableCell>
                  <StyledTableCell>Price (USD)</StyledTableCell>
                  <StyledTableCell align="center">Explorer</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((tx) => {
                  const usdPrice = tx.price * solPrice;
                  return (
                    <TableRow
                      key={tx.id}
                      style={{ backgroundColor: tx.type === 'sell' ? '#2f1a1a' : 'inherit' }}
                    >
                      <StyledTableCell>{calculateActiveDuration(tx.createdAt) || 0}</StyledTableCell>
                      <StyledTableCell>{tx.type}</StyledTableCell>
                      <StyledTableCell>{tx.amount}</StyledTableCell>
                      <StyledTableCell>
                        <FormattedValue value={tx.price.toString()} />
                      </StyledTableCell>
                      <StyledTableCell>
                        ${usdPrice.toFixed(8)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {tx.signature && (
                          <Tooltip title="View on Solana Explorer">
                            <IconButton
                              href={`https://solscan.io/tx/${tx.signature}?cluster=mainnet`}
                              target="_blank"
                              size="small"
                            >
                              <OpenInNewIcon style={{ color: '#2badee' }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      )}

      {value === 1 && (
        <Box mt={3} className="w-full overflow-x-auto">
          <Typography variant="h6" sx={{ color: '#fff', mb: 2 }}>
            Top Wallet Traders
          </Typography>
          <StyledTableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <Table className="min-w-[500px]">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Rank</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Bought</StyledTableCell>
                  <StyledTableCell>Sold</StyledTableCell>
                  <StyledTableCell>PNL</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {topTraders.map((trader, index) => (
                  <TableRow key={index}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{shortenAddress(trader.address)}</StyledTableCell>
                    <StyledTableCell>{trader.bought}</StyledTableCell>
                    <StyledTableCell>{trader.sold}</StyledTableCell>
                    <StyledTableCell>{trader.pnl}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      )}

      {value === 2 && (
        <Box mt={3} className="w-full overflow-x-auto">
          <Typography variant="h6" sx={{ color: '#fff', mb: 2 }}>
            All Holders
          </Typography>
          <StyledTableContainer component={Paper} sx={{ overflowX: 'auto' }}>
            <Table className="min-w-[500px]">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Rank</StyledTableCell>
                  <StyledTableCell>Wallet Address</StyledTableCell>
                  <StyledTableCell>Percentage</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {holders.map((holder) => (
                  <TableRow key={holder.address}>
                    <StyledTableCell>{holder.rank}</StyledTableCell>
                    <StyledTableCell>{shortenAddress(holder.address)}</StyledTableCell>
                    <StyledTableCell>{holder.percentage}%</StyledTableCell>
                    <StyledTableCell>{holder.amount}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      )}

      {value === 3 && (
        <Box mt={3} className="w-full">
          <CommentsSection tokenId={tokenId} user={user} />
        </Box>
      )}

      {totalPages > 1 && (
        <Box mt={4} display="flex" justifyContent="center">
          <StyledPagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
}

export default TokenDetailsTabs;
