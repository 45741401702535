import React, { useEffect, useState, useCallback, useContext, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Avatar, TextField, Button, LinearProgress, FormControl, InputAdornment, MenuItem, Select, Tooltip, IconButton, Modal } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StarIcon from '@mui/icons-material/Star';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import TokenDetailsTabs from './TokenDetailsTabs';
import axios from 'axios';
import CandlestickChartWithWebSocket from "./CandlestickChartWithWebSocket";
import { Transaction } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { AuthContext } from '../context/AuthContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  ArcElement,
  RadialLinearScale
} from 'chart.js';
import { Line, Bar, Doughnut, Pie, Radar, PolarArea, Bubble, Scatter } from 'react-chartjs-2';
import { calculateTokensReceived } from './bondingCurve';

import { Candlestick } from 'react-chartjs-2';
import { CandlestickController, CandlestickElement, OhlcElement } from 'chartjs-chart-financial';

const TokenDetailsPage = () => {
  const { tokenId } = useParams();
  const [tokens, setTokens] = useState(null);
  const navigate = useNavigate();
  const [tokenDetails, setTokenDetails] = useState(null);
  const [chartType, setChartType] = useState('Line');
  const [historicalData, setHistoricalData] = useState([]);
  const [ohlcData, setOhlcData] = useState([]);
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [estimatedTokens, setEstimatedTokens] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  const [estimatedSolReceived, setEstimatedSolReceived] = useState(0);
  const [progress, setProgress] = useState(0);
  const { publicKey, signTransaction } = useWallet();
  const { user } = useContext(AuthContext);
  const [transactionResult, setTransactionResult] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedToken, setSelectedToken] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [solPrice, setSolPrice] = useState(180);
  const [buyError, setBuyError] = useState(false);
  const [sellError, setSellError] = useState(false);
  const [buySlippage, setBuySlippage] = useState(0.5);
  const [sellSlippage, setSellSlippage] = useState(0.5);

  const DEFAULT_PRICE = 180.00;
  const A = 75;
  const N = 50000;
  const marketCapThreshold = 400 * solPrice;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('auth-token');
        // Fetch token details and historical data concurrently
        const [tokenDetailsResponse, historicalDataResponse] = await Promise.all([
          axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}`, {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`https://backend.memebrewery.fun/api/tokens/${tokenId}/history`, {
            withCredentials: true,
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        const tokenDetails = tokenDetailsResponse.data.token;
        setTokenDetails(tokenDetails);
        setProgress((tokenDetails.accumulatedSol / 400) * 100 || 0);

        const { historicalData, ohlcData } = historicalDataResponse.data;
        setHistoricalData(historicalData);
        setOhlcData(ohlcData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [tokenId]);

  const fetchTokens = async (query = '', page = 1, pageSize = 100) => {
    try {
      const token = localStorage.getItem('auth-token');
      const baseUrl = 'https://backend.memebrewery.fun/api/tokens';
      const url = `${baseUrl}?page=${page}&pageSize=${pageSize}${query ? `&${query}` : ''}`;

      const response = await axios.get(url, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.data || !response.data.tokens) {
        throw new Error('Invalid response structure');
      }

      const { tokens, totalPages, currentPage } = response.data;
      setTokens(tokens);
      setTotalPages(totalPages);
      setCurrentPage(currentPage);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const handlePurchaseAmountChange = async (e) => {
    const amountInSol = e.target.value;
    setPurchaseAmount(amountInSol);
    setBuyError(amountInSol === '' || isNaN(amountInSol) || parseFloat(amountInSol) <= 0);

    const parsedAmount = parseFloat(amountInSol);
    if (tokenDetails && !isNaN(parsedAmount)) {
      const tokens = calculateTokensReceived(parsedAmount, tokenDetails.availableSupply, A, N);
      setEstimatedTokens(tokens);
    } else {
      setEstimatedTokens(0);
    }
  };

  const handleSellAmountChange = (e) => {
    const amountToSell = e.target.value;
    setSellAmount(amountToSell);
    setSellError(amountToSell === '' || isNaN(amountToSell) || parseFloat(amountToSell) <= 0);

    const parseSellAmount = parseFloat(amountToSell);
    if (tokenDetails && !isNaN(parseSellAmount)) {
      const estimatedSol = parseSellAmount * tokenDetails.price;
      setEstimatedSolReceived(estimatedSol.toFixed(6));
    }
  };

  const ChartComponent = ({ chartType, historicalData, ohlcChartData }) => {
    const chartRef = useRef(null);
  
    // Define color schemes for each chart type
    const colorSchemes = {
      Line: {
        borderColor: 'rgba(43, 173, 238, 1)', // Blue
        backgroundColor: 'rgba(43, 173, 238, 0.2)',
      },
      Bar: {
        borderColor: 'rgba(255, 99, 132, 1)', // Red
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
      Doughnut: {
        borderColor: 'rgba(255, 206, 86, 1)', // Yellow
        backgroundColor: [
          'rgba(255, 206, 86, 0.2)', 
          'rgba(75,192,192,0.2)',
          'rgba(153,102,255,0.2)'
        ],
      },
      Pie: {
        borderColor: 'rgba(54,162,235,1)', // Blue
        backgroundColor: [
          'rgba(54,162,235,0.2)', 
          'rgba(255,99,132,0.2)',
          'rgba(255,206,86,0.2)'
        ],
      },
      Radar: {
        borderColor: 'rgba(75,192,192,1)', 
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
      PolarArea: {
        borderColor: 'rgba(255,159,64,1)', 
        backgroundColor: [
          'rgba(255,159,64,0.2)', 
          'rgba(54,162,235,0.2)',
          'rgba(255,99,132,0.2)'
        ],
      },
      Bubble: {
        borderColor: 'rgba(153,102,255,1)', 
        backgroundColor: 'rgba(153,102,255,0.2)',
      },
      Scatter: {
        borderColor: 'rgba(255,159,64,1)', 
        backgroundColor: 'rgba(255,159,64,0.2)',
      },
      Candlestick: {
        // Colors handled by CandlestickChartWithWebSocket directly
      },
    };
  
    useEffect(() => {
      if (chartRef.current) {
        const chart = chartRef.current;
  
        if (chartType === 'Candlestick') {
          chart.data.datasets[0].data = ohlcChartData.map((data) => ({
            t: new Date(data.t),
            o: data.o,
            h: data.h,
            l: data.l,
            c: data.c
          }));
        } else {
          chart.data.labels = historicalData.map((data) => data.date);
          chart.data.datasets[0].data = historicalData.map((data) => data.price);
        }
  
        chart.update('none'); // Update without animation
      }
    }, [chartType, historicalData, ohlcChartData]);
  
    const defaultColors = {
      borderColor: 'rgba(75,192,192,1)',
      backgroundColor: 'rgba(75,192,192,0.2)',
    };
  
    const { borderColor, backgroundColor } = colorSchemes[chartType] || defaultColors;
  
    const chartData = {
      labels: historicalData.map((data) => data.date),
      datasets: [
        {
          label: 'Price',
          data: historicalData.map((data) => data.price),
          fill: chartType !== 'Line', // Fill for non-line charts
          borderColor: borderColor,
          backgroundColor: backgroundColor,
          tension: 0.1,
        },
      ],
    };
  
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          type: 'time',
          time: { unit: 'day' },
        },
        y: {
          beginAtZero: false,
        },
      },
    };
  
    switch (chartType) {
      case 'Line':
        return <Line ref={chartRef} data={chartData} options={options} />;
      case 'Bar':
        return <Bar ref={chartRef} data={chartData} options={options} />;
      case 'Doughnut':
        return <Doughnut ref={chartRef} data={chartData} options={options} />;
      case 'Pie':
        return <Pie ref={chartRef} data={chartData} options={options} />;
      case 'Radar':
        return <Radar ref={chartRef} data={chartData} options={options} />;
      case 'PolarArea':
        return <PolarArea ref={chartRef} data={chartData} options={options} />;
      case 'Bubble':
        return <Bubble ref={chartRef} data={chartData} options={options} />;
      case 'Scatter':
        return <Scatter ref={chartRef} data={chartData} options={options} />;
      case 'Candlestick':
        return <CandlestickChartWithWebSocket ref={chartRef} tokenId={tokenId} historicalData={ohlcData} />;
      default:
        return <Line ref={chartRef} data={chartData} options={options} />;
    }
  };

  const FollowIconButton = ({ onClick, icon: IconComponent, tooltip, color }) => {
    return (
      <Tooltip title={tooltip}>
        <IconButton aria-label={tooltip} onClick={onClick} color={color}>
          <IconComponent />
        </IconButton>
      </Tooltip>
    );
  };

  const followToken = async (tokenId) => {
    try {
      const followerAddress = user.walletAddress;
      await axios.post('https://backend.memebrewery.fun/api/follow/tokens', {
        followerAddress,
        tokenId,
      });
      alert('Token followed successfully');
    } catch (error) {
      console.error('Error following token:', error);
      alert('Error following token');
    }
  };

  const followWallet = async (walletAddress) => {
    try {
      const followerAddress = user.walletAddress;
      await axios.post('https://backend.memebrewery.fun/api/follow/user', {
        followerAddress,
        tokenId: tokenDetails.id,
      });
      alert('User followed successfully');
    } catch (error) {
      console.error('Error following user:', error);
      alert('Error following user');
    }
  };

  const handlePurchase = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/buy', { tokenId: tokenDetails.id, amount: purchaseAmount }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const { transaction: base64Transaction } = response.data;
      const transaction = Transaction.from(Buffer.from(base64Transaction, 'base64'));
      const signedTransaction = await signTransaction(transaction);
      const serializedSignedTransaction = signedTransaction.serialize().toString('base64');

      const responseData = await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: serializedSignedTransaction,
        tokenId: tokenDetails.id,
        amount: purchaseAmount,
        phase: 'buy'
      }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const result = responseData.data;
      setTransactionResult({
        type: 'purchase',
        tokenName: tokenDetails.name,
        tokenSymbol: tokenDetails.symbol,
        transactionId: result.txid
      });

      setMessage('Purchase successful!');
      setSnackbarOpen(true);
      setSelectedToken(null);
      setPurchaseAmount(0);
      setEstimatedTokens(0);
      await fetchTokens();

    } catch (error) {
      setMessage('Purchase failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  const handleSell = async () => {
    try {
      const token = localStorage.getItem('auth-token');
      const response = await axios.post('https://backend.memebrewery.fun/api/sell', { tokenId: tokenDetails.id, amount: sellAmount }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const { transaction: base64Transaction } = response.data;
      const transaction = Transaction.from(Buffer.from(base64Transaction, 'base64'));
      const signedTransaction = await signTransaction(transaction);
      const serializedSignedTransaction = signedTransaction.serialize().toString('base64');

      const responseData = await axios.post('https://backend.memebrewery.fun/api/sign-and-send', {
        signedTransaction: serializedSignedTransaction,
        tokenId: tokenDetails.id,
        amount: sellAmount,
        phase: 'sell'
      }, {
        withCredentials: true,
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const result = responseData.data;
      setTransactionResult({
        type: 'sale',
        tokenName: tokenDetails.name,
        tokenSymbol: tokenDetails.symbol,
        transactionId: result.txid
      });

      setMessage('Sale successful!');
      setSnackbarOpen(true);
      setSelectedToken(null);
      setSellAmount(0);
      setEstimatedSolReceived(0);
      await fetchTokens();

    } catch (error) {
      setMessage('Sale failed: ' + (error.response?.data?.error || error.message));
      setSnackbarOpen(true);
    }
  };

  if (!tokenDetails) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="text.secondary">Loading...</Typography>
      </Box>
    );
  }

  return (
    <div className="relative flex min-h-screen flex-col bg-[#111c22] overflow-x-hidden">
      <header className="flex flex-col sm:flex-row items-center justify-between whitespace-nowrap border-b border-solid border-b-[#233c48] px-4 sm:px-10 py-3">
        <div className="flex items-center">
          <button
            onClick={() => navigate(-1)}
            className="mr-4 flex items-center text-white hover:text-[#2badee] transition-colors"
          >
            <ArrowBackIcon className="mr-2" />
            <span className="text-sm font-medium">Back</span>
          </button>
          <div className="flex flex-col items-center gap-4 text-white">
            <Avatar
              src={tokenDetails.icon}
              alt={`${tokenDetails.name} Icon`}
              sx={{ width: 100, height: 100 }}
            />
            <h2 className="text-white text-lg font-bold leading-tight tracking-[-0.015em] mt-2 text-center">
              {tokenDetails.name}
            </h2>
          </div>
        </div>
        <div className="flex flex-1 justify-end gap-8 mt-4 sm:mt-0">
          {/* Navigation or other header items */}
        </div>
      </header>

      <div className="gap-1 px-4 sm:px-6 flex flex-1 justify-center py-5 w-full">
        <div className="layout-content-container flex flex-col w-full max-w-full sm:max-w-[920px]">
          <div className="flex flex-col gap-2 text-left">
            <h1 className="text-white text-3xl sm:text-4xl font-black leading-tight tracking-[-0.033em]">
              {tokenDetails.name}
            </h1>
            <h2 className="text-white text-sm font-normal leading-normal">
              {tokenDetails.description}
            </h2>
          </div>

          {/* Chart Section */}
          <div className="flex flex-col min-w-0 flex-1 gap-4 rounded-xl border border-[#325567] p-4 sm:p-6 my-6">
            {/* Chart Type Selector */}
            <div className="flex flex-col gap-2">
              <Typography className="text-white text-base font-medium">Chart Type</Typography>
              <FormControl fullWidth>
                <Select
                  value={chartType}
                  onChange={(e) => setChartType(e.target.value)}
                  sx={{
                    bgcolor: '#2d3748',
                    color: '#fff',
                    borderRadius: 1,
                    '.MuiOutlinedInput-notchedOutline': { borderColor: '#325567' },
                  }}
                >
                  {['Line', 'Bar', 'Doughnut', 'Pie', 'Radar', 'PolarArea', 'Bubble', 'Scatter', 'Candlestick'].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* Chart Display */}
            <div className="flex min-h-[300px] flex-1 w-full">
              {chartType === 'Candlestick' ? (
                <CandlestickChartWithWebSocket tokenId={tokenId} historicalData={ohlcData} />
              ) : (
                <ChartComponent
                  chartType={chartType}
                  historicalData={historicalData}
                  ohlcChartData={ohlcData}
                />
              )}
            </div>
          </div>

          {/* Bonding Curve Section */}
          <Box mt={4}>
            <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
              Bonding Curve
            </Typography>
            <Box
              position="relative"
              display="flex"
              alignItems="center"
              sx={{
                mt: 2,
                bgcolor: '#2d3748',
                borderRadius: 2,
                padding: 2,
              }}
            >
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: 12,
                  width: '100%',
                  borderRadius: 5,
                  bgcolor: '#2d3748',
                  '& .MuiLinearProgress-bar': { bgcolor: '#00ff00' },
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontWeight: 'bold',
                  color: '#fff',
                }}
              >
                {progress.toFixed(2)}%
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                color: '#a0aec0',
              }}
            >
              Progress: {progress.toFixed(2)}% completed. Accumulated SOL: {tokenDetails.accumulatedSol || 0} of 400 SOL.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                color: '#e2e8f0',
              }}
            >
              Available tokens:
              <span style={{ color: '#00FF00', fontWeight: 'bold' }}>
                {new Intl.NumberFormat().format(tokenDetails.availableSupply || 0)}
              </span>
              .
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 2,
                color: '#e2e8f0',
              }}
            >
              When the market cap reaches{' '}
              <span style={{ color: '#00FF00', fontWeight: 'bold' }}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                }).format(marketCapThreshold || 0)}
              </span>
              , all liquidity from the bonding curve will be deposited into Raydium and burned.
            </Typography>
          </Box>

          {/* Buy & Sell Widgets - Responsive Stacking */}
          <div className="flex flex-col sm:flex-row gap-4 mt-6 w-full">
            {/* Buy Widget */}
            <div className="flex flex-col items-start justify-between gap-4 rounded-xl border border-[#325567] bg-[#111c22] p-4 sm:p-5 w-full sm:w-1/2">
              <div className="flex flex-col gap-1">
                <p className="text-white text-base font-bold leading-tight">Buy {tokenDetails.name}</p>
                <p className="text-white text-sm font-normal leading-normal">Slippage Tolerance:</p>
                <input
                  type="number"
                  placeholder="0.5%"
                  className="w-20 bg-[#233c48] text-white rounded-md p-1 text-right"
                  value={buySlippage}
                  onChange={(e) => setBuySlippage(e.target.value)}
                />
              </div>
              <div className="flex max-w-full flex-wrap items-end gap-4 w-full">
                <label className="flex flex-col min-w-40 flex-1 w-full">
                  <p className="text-white text-base font-medium leading-normal pb-2">From (estimated)</p>
                  <input
                    placeholder="0 SOL"
                    className="form-input w-full resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border-none bg-[#233c48] h-14 placeholder:text-[#92b7c9] p-4 text-base font-normal leading-normal"
                    value={purchaseAmount}
                    onChange={handlePurchaseAmountChange}
                  />
                </label>
              </div>
              <div className="flex w-full justify-between items-center">
                <p className="text-white text-sm font-normal leading-normal">Estimated Tokens:</p>
                <p className="text-white text-sm font-normal leading-normal">{estimatedTokens}</p>
              </div>
              <button
                onClick={handlePurchase}
                className="flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#2badee] text-[#111c22] text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Buy</span>
              </button>
            </div>

            {/* Sell Widget */}
            <div className="flex flex-col items-start justify-between gap-4 rounded-xl border border-[#325567] bg-[#111c22] p-4 sm:p-5 w-full sm:w-1/2">
              <div className="flex flex-col gap-1">
                <p className="text-white text-base font-bold leading-tight">Sell {tokenDetails.name}</p>
                <p className="text-white text-sm font-normal leading-normal">Slippage Tolerance:</p>
                <input
                  type="number"
                  placeholder="0.5%"
                  className="w-20 bg-[#233c48] text-white rounded-md p-1 text-right"
                  value={sellSlippage}
                  onChange={(e) => setSellSlippage(e.target.value)}
                />
              </div>
              <div className="flex max-w-full flex-wrap items-end gap-4 w-full">
                <label className="flex flex-col min-w-40 flex-1 w-full">
                  <p className="text-white text-base font-medium leading-normal pb-2">Amount to Sell</p>
                  <input
                    placeholder={`0 ${tokenDetails.symbol}`}
                    className="form-input w-full resize-none overflow-hidden rounded-xl text-white focus:outline-0 focus:ring-0 border-none bg-[#233c48] h-14 placeholder:text-[#92b7c9] p-4 text-base font-normal leading-normal"
                    value={sellAmount}
                    onChange={handleSellAmountChange}
                  />
                </label>
              </div>
              <div className="flex w-full justify-between items-center">
                <p className="text-white text-sm font-normal leading-normal">Estimated SOL:</p>
                <p className="text-white text-sm font-normal leading-normal">{estimatedSolReceived}</p>
              </div>
              <button
                onClick={handleSell}
                className="flex w-full cursor-pointer items-center justify-center overflow-hidden rounded-xl h-10 px-4 bg-[#2badee] text-[#111c22] text-sm font-bold leading-normal tracking-[0.015em]"
              >
                <span className="truncate">Sell</span>
              </button>
            </div>
          </div>

          <TokenDetailsTabs tokenId={tokenId} />
        </div>
      </div>

      {/* Transaction Result Modal */}
      {transactionResult && (
        <Modal open={!!transactionResult} onClose={() => setTransactionResult(null)}>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-sm bg-[#111c22] border border-[#233c48] rounded-xl p-5 shadow-lg flex flex-col px-4">
            <Typography variant="h5" component="h2" className="text-white font-bold mb-4">
              🎉 {transactionResult.type === 'purchase' ? 'Successful Purchase!' : 'Successful Sale!'}
            </Typography>
            <Typography variant="body1" className="text-white font-bold mb-1">
              {transactionResult.tokenName} ({transactionResult.tokenSymbol})
            </Typography>
            <Typography variant="body2" className="text-[#a0aec0] mb-4">
              {transactionResult.type === 'purchase'
                ? 'You have successfully purchased this token.'
                : 'You have successfully sold this token.'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href={`https://explorer.solana.com/tx/${transactionResult.transactionId}?cluster=mainnet`}
              target="_blank"
              className="bg-[#2badee] text-[#111c22] font-bold mb-2 hover:bg-[#1d7eb5]"
              startIcon={<OpenInNewIcon />}
            >
              View Transaction
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setTransactionResult(null)}
              className="border-[#2badee] text-[#2badee] hover:bg-[#233c48]"
            >
              Close
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TokenDetailsPage;
